var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "v-fixed-right",
          staticStyle: { "z-index": "999999 !important" },
          attrs: { app: "", fixed: "", temporary: "", right: "", width: "450" },
          model: {
            value: _vm.snapshotDialog,
            callback: function ($$v) {
              _vm.snapshotDialog = $$v
            },
            expression: "snapshotDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "", height: "100%" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "headline d-flex justify-space-between px-5 py-3 mb-0",
                },
                [
                  _c("h5", { staticClass: "text-h6" }, [
                    _vm._v("Last Run Result"),
                  ]),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.snapshotDialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-3 pt-0" },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { grow: "" },
                      model: {
                        value: _vm.runResultTab,
                        callback: function ($$v) {
                          _vm.runResultTab = $$v
                        },
                        expression: "runResultTab",
                      },
                    },
                    [
                      _c("v-tab", [_vm._v("Basic Log")]),
                      _c("v-tab", [_vm._v("Detail Log")]),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.runResultTab,
                        callback: function ($$v) {
                          _vm.runResultTab = $$v
                        },
                        expression: "runResultTab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "keword-lists pa-3 pt-0" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-4" },
                                      [
                                        _c("json-viewer", {
                                          attrs: {
                                            "expand-depth": 2,
                                            value: _vm.jsonData,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "keword-lists pa-3 pt-0" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-4" },
                                      [
                                        _c("json-viewer", {
                                          attrs: {
                                            "expand-depth": 1,
                                            value: _vm.detailSARLog,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-space-between pa-3" },
                [_c("div", { staticClass: "right-action-btn d-flex" })]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-with-tabs" },
          [
            _c(
              "div",
              {
                staticClass: "top-page-header page-header-with-banner",
                attrs: { lang: _vm.locale },
              },
              [
                _c(
                  "v-tabs",
                  {
                    staticClass: "custom-tab mb-3",
                    attrs: {
                      "background-color": "transparent",
                      "center-active": "",
                      "show-arrows": "",
                    },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  _vm._l(_vm.items, function (item, i) {
                    return _c(
                      "v-tab",
                      {
                        key: i,
                        on: {
                          change: function ($event) {
                            return _vm.onTabChange(i)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content automation-top stepper-template",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 rounded-lg extra-padding",
                                  attrs: { flat: "", elevation: "3" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "pa-0  d-flex align-baseline justify-end  automation-header",
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "max-275 mx-2 mb-2",
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label: _vm.$t("labels.filterItem"),
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.combinedSearch,
                                          callback: function ($$v) {
                                            _vm.combinedSearch = $$v
                                          },
                                          expression: "combinedSearch",
                                        },
                                      }),
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "v-dialog",
                                              {
                                                attrs: {
                                                  width: "642",
                                                  persistent: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary v-btn-sm mx-2",
                                                                  dark: "",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " Create Rule "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.dialog,
                                                  callback: function ($$v) {
                                                    _vm.dialog = $$v
                                                  },
                                                  expression: "dialog",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "  camp-view   rounded-lg",
                                                    attrs: { elevation: "3" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      { attrs: { flat: "" } },
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "px-0 py-0",
                                                          },
                                                          [
                                                            _c(
                                                              "v-form",
                                                              {
                                                                staticClass:
                                                                  "px-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "sub-heading-with-btn mb-0  d-flex flex-column align-start sticky-top",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h5",
                                                                      {
                                                                        staticClass:
                                                                          " primarybase--text h5--text-bold mb-3 w-100 d-flex justify-space-between",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " Add Rule"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex justify-end primarybase--text close",
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                _vm.dialog = false
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-close"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-radio-group",
                                                                  {
                                                                    staticClass:
                                                                      "mt-0",
                                                                    model: {
                                                                      value:
                                                                        _vm.radioGroup,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.radioGroup = $$v
                                                                      },
                                                                      expression:
                                                                        "radioGroup",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      {
                                                                        staticClass:
                                                                          "mt-0",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "py-0",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "6",
                                                                              lg:
                                                                                "6",
                                                                              md:
                                                                                "6",
                                                                              xl:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {
                                                                                  class:
                                                                                    _vm.radioGroup ==
                                                                                    0
                                                                                      ? "camp-upload-file align-start bg-active"
                                                                                      : "camp-upload-file align-start bg-hover",
                                                                                },
                                                                                !_vm.isReadOnly()
                                                                                  ? {
                                                                                      click: function () {
                                                                                        return _vm.createKWJ()
                                                                                      },
                                                                                    }
                                                                                  : {
                                                                                      click: function () {
                                                                                        return _vm.openPermissionReadOnlyPopup()
                                                                                      },
                                                                                    }
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pb-4",
                                                                                    attrs: {
                                                                                      block:
                                                                                        "",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-radio",
                                                                                  {
                                                                                    attrs: {
                                                                                      value: 0,
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "label",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        " mb-3 h7--text-bold ",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Keyword Journey "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "sup",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "TM"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        " caption--text-bold",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " Configure our pre-defined Keyword Journey automation rule set to achieve balance between optimizing spend and exploring new keywords for maximum efficiency and performance. "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ]
                                                                                    ),
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "py-0",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "6",
                                                                              lg:
                                                                                "6",
                                                                              md:
                                                                                "6",
                                                                              xl:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {
                                                                                  class:
                                                                                    _vm.radioGroup ==
                                                                                    3
                                                                                      ? "camp-upload-file align-start bg-active"
                                                                                      : "camp-upload-file align-start bg-hover",
                                                                                },
                                                                                !_vm.isReadOnly()
                                                                                  ? {
                                                                                      click: function () {
                                                                                        return _vm.createSL()
                                                                                      },
                                                                                    }
                                                                                  : {
                                                                                      click: function () {
                                                                                        return _vm.openPermissionReadOnlyPopup()
                                                                                      },
                                                                                    }
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pb-4",
                                                                                    attrs: {
                                                                                      block:
                                                                                        "",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-radio",
                                                                                  {
                                                                                    attrs: {
                                                                                      value: 3,
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "label",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        " mb-3 h7--text-bold ",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Strategic Losing "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "sup",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "TM"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        " caption--text-bold",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " Win when it's profitable and lose with the second highest bid when it's not. Drive your competitor's costs up and decrease competition for future auctions. "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ]
                                                                                    ),
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "py-0",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "6",
                                                                              lg:
                                                                                "6",
                                                                              md:
                                                                                "6",
                                                                              xl:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {
                                                                                  class:
                                                                                    _vm.radioGroup ==
                                                                                    5
                                                                                      ? "camp-upload-file align-start bg-active"
                                                                                      : "camp-upload-file align-start bg-hover",
                                                                                },
                                                                                !_vm.isReadOnly()
                                                                                  ? {
                                                                                      click: function () {
                                                                                        return _vm.createLDW()
                                                                                      },
                                                                                    }
                                                                                  : {
                                                                                      click: function () {
                                                                                        return _vm.openPermissionReadOnlyPopup()
                                                                                      },
                                                                                    }
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pb-4",
                                                                                    attrs: {
                                                                                      block:
                                                                                        "",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-radio",
                                                                                  {
                                                                                    attrs: {
                                                                                      value: 5,
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "label",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        " mb-3 h7--text-bold ",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Late Day Winning "
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "sup",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "TM"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        " caption--text-bold",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " Burn the competition budgets in the morning and then pick up cheaper taps in the afternoon. Great option for campaigns that often hit their daily cap. "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ]
                                                                                    ),
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "py-0",
                                                                            attrs: {
                                                                              cols:
                                                                                "12",
                                                                              sm:
                                                                                "6",
                                                                              lg:
                                                                                "6",
                                                                              md:
                                                                                "6",
                                                                              xl:
                                                                                "6",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {
                                                                                  class:
                                                                                    _vm.radioGroup ==
                                                                                    2
                                                                                      ? "camp-upload-file align-start bg-active"
                                                                                      : "camp-upload-file align-start bg-hover",
                                                                                },
                                                                                !_vm.isReadOnly()
                                                                                  ? {
                                                                                      click: function () {
                                                                                        return _vm.createSAR()
                                                                                      },
                                                                                    }
                                                                                  : {
                                                                                      click: function () {
                                                                                        return _vm.openPermissionReadOnlyPopup()
                                                                                      },
                                                                                    }
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pb-4",
                                                                                    attrs: {
                                                                                      block:
                                                                                        "",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-radio",
                                                                                  {
                                                                                    attrs: {
                                                                                      value: 2,
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "label",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _c(
                                                                                                    "h4",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "h7--text-bold mb-3 ",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Advanced Rule"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "caption--text-bold",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " Customize and fine-tune your automation for limitless possibilities that perfectly match your strategy. "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ]
                                                                                    ),
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      _c("CreateRule", {
                                        attrs: {
                                          fromRulesPage: _vm.dataToCreateRule,
                                        },
                                        on: {
                                          fromCreateRule: function ($event) {
                                            return _vm.dataFromCreateRule(
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { staticStyle: { display: "block" } },
                                        [
                                          _c("v-data-table", {
                                            staticClass:
                                              "elevation-0 mt-4 table-style-3",
                                            attrs: {
                                              search: _vm.combinedSearch,
                                              headers: _vm.mergedHeaders,
                                              items: _vm.mergedItems,
                                              "items-per-page": 25,
                                              "item-key": "_id",
                                              loading: _vm.loading,
                                              "loading-text":
                                                "Loading... Please wait",
                                              "fixed-header": "",
                                              "no-data-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                              "no-results-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                              "footer-props": {
                                                itemsPerPageText: _vm.$t(
                                                  "campaignGenerator.footerProps.itemsPerPageText"
                                                ),
                                                itemsPerPageAllText: _vm.$t(
                                                  "campaignGenerator.footerProps.itemsPerPageAllText"
                                                ),
                                                itemsPerPageOptions: [
                                                  25,
                                                  50,
                                                  -1,
                                                ],
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.sno",
                                                  fn: function (ref) {
                                                    var index = ref.index
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(index + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.rulesType",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.status
                                                        ? [
                                                            _vm._v(
                                                              " Advanced Rule "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.arStatus
                                                        ? [
                                                            _vm._v(
                                                              " Standard Rule "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.runEvery",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.status
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.runEvery
                                                                ) +
                                                                " Hours "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.arStatus
                                                        ? [
                                                            item.settings
                                                              .ruleSettings
                                                              .checkFrequency ==
                                                            _vm
                                                              .RuleCheckFrequenciesEnum
                                                              .EVERY_6_HRS
                                                              ? [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createAR.actionFreqsText2"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                            item.settings
                                                              .ruleSettings
                                                              .checkFrequency ==
                                                            _vm
                                                              .RuleCheckFrequenciesEnum
                                                              .EVERY_12_HRS
                                                              ? [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createAR.actionFreqsText3"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                            item.settings
                                                              .ruleSettings
                                                              .checkFrequency ==
                                                            _vm
                                                              .RuleCheckFrequenciesEnum
                                                              .EVERY_DAY
                                                              ? [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createAR.actionFreqsText4"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.disable",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.status
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  item.status !==
                                                                  "COMPLETED"
                                                                    ? {
                                                                        key:
                                                                          "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "min-content",
                                                                                    },
                                                                                  },
                                                                                  "div",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch",
                                                                                      attrs: {
                                                                                        inset:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item.enabled,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "enabled",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.enabled",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.sarAction(
                                                                                              item.disable
                                                                                                ? 3
                                                                                                : 2,
                                                                                              {
                                                                                                _id:
                                                                                                  item._id,
                                                                                                action: item.disable
                                                                                                  ? false
                                                                                                  : true,
                                                                                              }
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      }
                                                                    : null,
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              !item.disable
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "Click to Disable"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.disable
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "Click to Enable"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.arStatus
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "min-content",
                                                                                },
                                                                              },
                                                                              "div",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            item.arStatus ==
                                                                            "RUNNING"
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch",
                                                                                      attrs: {
                                                                                        inset:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        "true-value":
                                                                                          "RUNNING",
                                                                                        "false-value":
                                                                                          "DISABLED",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item.arStatus,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "arStatus",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.arStatus",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.disableARRule(
                                                                                              item.ruleId
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                )
                                                                              : _vm._e(),
                                                                            item.arStatus ==
                                                                            "DISABLED"
                                                                              ? _c(
                                                                                  "v-switch",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "borderedSwitch",
                                                                                      attrs: {
                                                                                        inset:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                        "true-value":
                                                                                          "RUNNING",
                                                                                        "false-value":
                                                                                          "DISABLED",
                                                                                        readonly:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item.arStatus,
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "arStatus",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.arStatus",
                                                                                      },
                                                                                    },
                                                                                    !_vm.isReadOnly()
                                                                                      ? {
                                                                                          click: function () {
                                                                                            return _vm.enableARRule(
                                                                                              item.ruleId
                                                                                            )
                                                                                          },
                                                                                        }
                                                                                      : {
                                                                                          click: function () {
                                                                                            return _vm.openPermissionReadOnlyPopup()
                                                                                          },
                                                                                        }
                                                                                  )
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              item.arStatus ==
                                                              "RUNNING"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "Click to Disable"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              item.arStatus ==
                                                              "DISABLED"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "Click to Enable "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.lastRun",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.status
                                                        ? [
                                                            item.lastRun &&
                                                            item.lastRun
                                                              .success === 0
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDataSet(
                                                                          item.lastRun,
                                                                          item.ruleId
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Error "
                                                                    ),
                                                                  ]
                                                                )
                                                              : item.lastRun &&
                                                                item.lastRun
                                                                  .success === 1
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDataSet(
                                                                          item.lastRun,
                                                                          item.ruleId
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Success "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDataSet(
                                                                          item.lastRun
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Yet to run "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        : _vm._e(),
                                                      item.arStatus
                                                        ? [
                                                            item.arStatus ==
                                                            "RUNNING"
                                                              ? _c("span", [
                                                                  _vm._v("N/A"),
                                                                ])
                                                              : _vm._e(),
                                                            item.arStatus ==
                                                            "DISABLED"
                                                              ? _c("span", [
                                                                  _vm._v("N/A"),
                                                                ])
                                                              : _vm._e(),
                                                            item.arStatus ==
                                                            "COMPLETED"
                                                              ? _c("span", [
                                                                  _vm._v("N/A"),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.actions",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.status
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                color:
                                                                  "primarydark",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        item.status !==
                                                                          "COMPLETED" &&
                                                                        _vm.enableSARLiveRun
                                                                          ? _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1 font-22 icon-hover",
                                                                                    attrs: {
                                                                                      color: item.hideLiveRun
                                                                                        ? "secondary"
                                                                                        : "primary",
                                                                                      dark:
                                                                                        "",
                                                                                    },
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.liveRun(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.hideLiveRun
                                                                                        ? "mdi-timelapse mdi-spin"
                                                                                        : "mdi-play"
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Live run"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.status
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                color:
                                                                  "primarydark",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        item.status !==
                                                                        "COMPLETED"
                                                                          ? _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1 font-22 icon-hover",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary",
                                                                                      dark:
                                                                                        "",
                                                                                    },
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.editSAR(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-pencil-outline "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("Edit"),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.arStatus
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                color:
                                                                  "primarydark",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        item.arStatus !==
                                                                        "COMPLETED"
                                                                          ? _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1 font-22 icon-hover",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary",
                                                                                      dark:
                                                                                        "",
                                                                                    },
                                                                                    on: {
                                                                                      click: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.editARRule(
                                                                                          item.ruleId
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  " mdi-pencil-outline "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("Edit"),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.status
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                color:
                                                                  "primarydark",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1 icon-hover",
                                                                                attrs: {
                                                                                  run:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                  dark:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.sarAction(
                                                                                      1,
                                                                                      {
                                                                                        _id:
                                                                                          item._id,
                                                                                      }
                                                                                    )
                                                                                  },
                                                                                },
                                                                              },
                                                                              "v-icon",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-delete-outline "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Delete"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.arStatus
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                color:
                                                                  "primarydark",
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1 icon-hover",
                                                                                attrs: {
                                                                                  run:
                                                                                    "",
                                                                                  color:
                                                                                    "primary",
                                                                                  dark:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.deleteARRule(
                                                                                      item.ruleId
                                                                                    )
                                                                                  },
                                                                                },
                                                                              },
                                                                              "v-icon",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-delete-outline "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Delete"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            color:
                                                              "primarydark",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    item.arStatus
                                                                      ? _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1 font-22 icon-hover",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  dark:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.cloneARRule(
                                                                                      item.ruleId
                                                                                    )
                                                                                  },
                                                                                },
                                                                              },
                                                                              "v-icon",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-content-copy "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Clone"),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            color:
                                                              "primarydark",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    item.arStatus ==
                                                                    "COMPLETED"
                                                                      ? _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1 font-22 icon-hover",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  dark:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.viewARRule(
                                                                                      item.ruleId
                                                                                    )
                                                                                  },
                                                                                },
                                                                              },
                                                                              "v-icon",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-eye-outline "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Preview"),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            color:
                                                              "primarydark",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    item.arStatus ==
                                                                    "COMPLETED"
                                                                      ? _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1 icon-hover ",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  dark:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.reactivateARRule(
                                                                                      item.ruleId
                                                                                    )
                                                                                  },
                                                                                },
                                                                              },
                                                                              "v-icon",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-restart "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Run"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.selectedItems,
                                              callback: function ($$v) {
                                                _vm.selectedItems = $$v
                                              },
                                              expression: "selectedItems",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 rounded-lg extra-padding",
                                  attrs: { flat: "", elevation: "3" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "pa-0  d-flex align-baseline justify-end automation-header",
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "max-275 mx-2",
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label: _vm.$t("labels.filterItem"),
                                          "single-line": "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.searchLogs,
                                          callback: function ($$v) {
                                            _vm.searchLogs = $$v
                                          },
                                          expression: "searchLogs",
                                        },
                                      }),
                                      _c("v-autocomplete", {
                                        staticClass: "rounded-lg max-275",
                                        attrs: {
                                          items: _vm.searchLogItems,
                                          "item-text": "ruleName",
                                          "item-value": "ruleId",
                                          required: "",
                                          outlined: "",
                                          label: "Filter the Logs by Rule",
                                          dense: "",
                                          "return-object": "",
                                          disabled: _vm.searchLog.length < 2,
                                        },
                                        on: { change: _vm.changeLog },
                                        model: {
                                          value: _vm.searchLog,
                                          callback: function ($$v) {
                                            _vm.searchLog = $$v
                                          },
                                          expression: "searchLog",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { staticStyle: { display: "block" } },
                                        [
                                          _c("v-data-table", {
                                            staticClass:
                                              "elevation-0 mt-4 table-style-3",
                                            attrs: {
                                              search: _vm.searchLogs,
                                              headers: _vm.logHeaders,
                                              items: _vm.mergedDataItems,
                                              "items-per-page": 10,
                                              "item-key": "_id",
                                              expanded: _vm.expanded,
                                              "single-expand": true,
                                              loading: _vm.logLoading,
                                              "loading-text":
                                                "Loading... Please wait",
                                              "fixed-header": "",
                                              "show-expand": "",
                                              "no-data-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                              "no-results-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                              "footer-props": {
                                                itemsPerPageText: _vm.$t(
                                                  "campaignGenerator.footerProps.itemsPerPageText"
                                                ),
                                                itemsPerPageAllText: _vm.$t(
                                                  "campaignGenerator.footerProps.itemsPerPageAllText"
                                                ),
                                              },
                                            },
                                            on: {
                                              "update:expanded": function (
                                                $event
                                              ) {
                                                _vm.expanded = $event
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.sno",
                                                  fn: function (ref) {
                                                    var index = ref.index
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(index + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.logStatus",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.logStatus
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createAR." +
                                                                      item.logStatus
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.status
                                                        ? [
                                                            item.lastRun &&
                                                            item.lastRun
                                                              .success === 0
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDataSet(
                                                                          item.lastRun,
                                                                          item.ruleId
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " ERROR "
                                                                    ),
                                                                  ]
                                                                )
                                                              : item.lastRun &&
                                                                item.lastRun
                                                                  .success === 1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDataSet(
                                                                          item.lastRun,
                                                                          item.ruleId
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " SUCCESS "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openDataSet(
                                                                          item.lastRun
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " YET TO RUN "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "expanded-item",
                                                  fn: function (ref) {
                                                    var headers = ref.headers
                                                    var item = ref.item
                                                    return [
                                                      item.logStatus ===
                                                        "SUCCESS" &&
                                                      item.data &&
                                                      item.data.messages
                                                        ? _vm._l(
                                                            item.data.messages,
                                                            function (
                                                              messageItem
                                                            ) {
                                                              return _c(
                                                                "tr",
                                                                {
                                                                  key: messageItem,
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      attrs: {
                                                                        colspan:
                                                                          _vm
                                                                            .logHeaders
                                                                            .length,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            messageItem
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      item.logStatus ===
                                                        "RUNFAILED" &&
                                                      item.runLogs
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "pa-4 rounded-lg extra-padding",
                                                                    staticStyle: {
                                                                      border:
                                                                        "0px",
                                                                    },
                                                                    attrs: {
                                                                      flat: "",
                                                                      elevation:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Last Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            new Date(
                                                                              item.runLogs.runStartAt
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Next Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            new Date(
                                                                              item
                                                                                .runLogs
                                                                                .runEndAt +
                                                                                3600000 *
                                                                                  _vm.getFreqValue(
                                                                                    item.ruleFreq
                                                                                  )
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Run status : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getRunStatusString(
                                                                                item
                                                                                  .runLogs
                                                                                  .runningStep
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    item.runLogs &&
                                                                    item.runLogs
                                                                      .errors
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  "Error messages : "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-list",
                                                                              {
                                                                                staticStyle: {
                                                                                  padding:
                                                                                    "1px 0px",
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._l(
                                                                                  [
                                                                                    0,
                                                                                  ],
                                                                                  function (
                                                                                    error,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        key: index,
                                                                                        staticStyle: {
                                                                                          "align-items":
                                                                                            "start",
                                                                                          "min-height":
                                                                                            "12px",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              color:
                                                                                                "crimson",
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .runLogs
                                                                                                  .errors[
                                                                                                  index
                                                                                                ]
                                                                                                  .message
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors
                                                                                          .length >
                                                                                          0 &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject
                                                                                          .data &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject
                                                                                          .data
                                                                                          .error &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject
                                                                                          .data
                                                                                          .error
                                                                                          .errors &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject
                                                                                          .data
                                                                                          .error
                                                                                          .errors
                                                                                          .length >
                                                                                          0 &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject
                                                                                          .data
                                                                                          .error
                                                                                          .errors[0]
                                                                                          .messageCode
                                                                                          ? _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  color:
                                                                                                    "crimson",
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "     -     Invalid Request to ASA "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors
                                                                                          .length >
                                                                                          0 &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject &&
                                                                                        item
                                                                                          .runLogs
                                                                                          .errors[0]
                                                                                          .errorObject
                                                                                          .stack
                                                                                          ? _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  color:
                                                                                                    "crimson",
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "     -     Unable to parse request/response object "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                item
                                                                                  .runLogs
                                                                                  .errors
                                                                                  .length >
                                                                                  0 &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject
                                                                                  .data &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject
                                                                                  .data
                                                                                  .error &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject
                                                                                  .data
                                                                                  .error
                                                                                  .errors &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject
                                                                                  .data
                                                                                  .error
                                                                                  .errors
                                                                                  .length >
                                                                                  0 &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject
                                                                                  .data
                                                                                  .error
                                                                                  .errors[0]
                                                                                  .messageCode
                                                                                  ? _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        key:
                                                                                          _vm.index,
                                                                                        staticStyle: {
                                                                                          "align-items":
                                                                                            "start",
                                                                                          "min-height":
                                                                                            "12px",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        [
                                                                                          _c(
                                                                                            "v-simple-table",
                                                                                            {
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "default",
                                                                                                    fn: function () {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "thead",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "tr",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "th",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-left",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " S.No "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "th",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-left",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " Message Code "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "th",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-left",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " Error Message "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "tbody",
                                                                                                          _vm._l(
                                                                                                            item
                                                                                                              .runLogs
                                                                                                              .errors[0]
                                                                                                              .errorObject
                                                                                                              .data
                                                                                                              .error
                                                                                                              .errors,
                                                                                                            function (
                                                                                                              error,
                                                                                                              index
                                                                                                            ) {
                                                                                                              return _c(
                                                                                                                "tr",
                                                                                                                {
                                                                                                                  key: index,
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "td",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          index +
                                                                                                                            1
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "td",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          error.messageCode
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "td",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          error.message
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              )
                                                                                                            }
                                                                                                          ),
                                                                                                          0
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true,
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                      ],
                                                                                      2
                                                                                    )
                                                                                  : _vm._e(),
                                                                                item
                                                                                  .runLogs
                                                                                  .errors
                                                                                  .length >
                                                                                  0 &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject &&
                                                                                item
                                                                                  .runLogs
                                                                                  .errors[0]
                                                                                  .errorObject
                                                                                  .stack
                                                                                  ? _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        staticStyle: {
                                                                                          "align-items":
                                                                                            "start",
                                                                                          "min-height":
                                                                                            "12px",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle: {
                                                                                              color:
                                                                                                "crimson",
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .runLogs
                                                                                                  .errors[0]
                                                                                                  .errorObject
                                                                                                  .message
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              2
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._l(
                                                                              item
                                                                                .runLogs
                                                                                .infoLogs,
                                                                              function (
                                                                                iLog,
                                                                                index
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          iLog.message
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.logStatus ===
                                                      "ASACONDITIONNOTMATCHED"
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "pa-4 rounded-lg extra-padding",
                                                                    staticStyle: {
                                                                      border:
                                                                        "0px",
                                                                    },
                                                                    attrs: {
                                                                      flat: "",
                                                                      elevation:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Last Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              new Date(
                                                                                item.runLogs.runStartAt
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Next Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              new Date(
                                                                                item
                                                                                  .runLogs
                                                                                  .runEndAt +
                                                                                  3600000 *
                                                                                    _vm.getFreqValue(
                                                                                      item.ruleFreq
                                                                                    )
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Run status : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getRunStatusString(
                                                                                item
                                                                                  .runLogs
                                                                                  .runningStep
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Conditions Validation Results : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        item
                                                                          .runLogs
                                                                          .rowLogsASA &&
                                                                        item
                                                                          .runLogs
                                                                          .rowLogsASA
                                                                          .length >
                                                                          0
                                                                          ? _c(
                                                                              "v-data-table",
                                                                              {
                                                                                staticClass:
                                                                                  "elevation-1",
                                                                                attrs: {
                                                                                  "items-per-page":
                                                                                    item
                                                                                      .runLogs
                                                                                      .rowLogsASA
                                                                                      .length,
                                                                                  headers:
                                                                                    _vm.rowLogsheaders,
                                                                                  items:
                                                                                    item
                                                                                      .runLogs
                                                                                      .rowLogsASA,
                                                                                  "hide-default-footer":
                                                                                    "",
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "item.condition.fieldpath",
                                                                                      fn: function (
                                                                                        ref
                                                                                      ) {
                                                                                        var item =
                                                                                          ref.item
                                                                                        return [
                                                                                          item
                                                                                            .condition
                                                                                            .fieldpath ==
                                                                                          "localSpend.amount"
                                                                                            ? [
                                                                                                _vm._v(
                                                                                                  " Spend "
                                                                                                ),
                                                                                              ]
                                                                                            : [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    item
                                                                                                      .condition
                                                                                                      .fieldpath
                                                                                                  )
                                                                                                ),
                                                                                              ],
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  "***Validation Logs : "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._l(
                                                                              item
                                                                                .runLogs
                                                                                .infoLogs,
                                                                              function (
                                                                                iLog,
                                                                                index
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          iLog.message
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.logStatus ===
                                                      "MMPCONDITIONNOTMATCHED"
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "pa-4 rounded-lg extra-padding",
                                                                    staticStyle: {
                                                                      border:
                                                                        "0px",
                                                                    },
                                                                    attrs: {
                                                                      flat: "",
                                                                      elevation:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Last Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              new Date(
                                                                                item.runLogs.runStartAt
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Next Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              new Date(
                                                                                item
                                                                                  .runLogs
                                                                                  .runEndAt +
                                                                                  3600000 *
                                                                                    _vm.getFreqValue(
                                                                                      item.ruleFreq
                                                                                    )
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Run status : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getRunStatusString(
                                                                                item
                                                                                  .runLogs
                                                                                  .runningStep
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Conditions Validation Results : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        item
                                                                          .runLogs
                                                                          .rowLogsMMP &&
                                                                        item
                                                                          .runLogs
                                                                          .rowLogsMMP
                                                                          .length >
                                                                          0
                                                                          ? _c(
                                                                              "v-data-table",
                                                                              {
                                                                                staticClass:
                                                                                  "elevation-1",
                                                                                attrs: {
                                                                                  "items-per-page":
                                                                                    item
                                                                                      .runLogs
                                                                                      .rowLogsMMP
                                                                                      .length,
                                                                                  headers:
                                                                                    _vm.rowLogsMMPheaders,
                                                                                  items:
                                                                                    item
                                                                                      .runLogs
                                                                                      .rowLogsMMP,
                                                                                  "hide-default-footer":
                                                                                    "",
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "item.condition.fieldpath",
                                                                                      fn: function (
                                                                                        ref
                                                                                      ) {
                                                                                        var item =
                                                                                          ref.item
                                                                                        return [
                                                                                          item
                                                                                            .condition
                                                                                            .fieldpath ==
                                                                                          "localSpend.amount"
                                                                                            ? [
                                                                                                _vm._v(
                                                                                                  " Spend "
                                                                                                ),
                                                                                              ]
                                                                                            : [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    item
                                                                                                      .condition
                                                                                                      .fieldpath
                                                                                                  )
                                                                                                ),
                                                                                              ],
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  "***Validation Logs : "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._l(
                                                                              item
                                                                                .runLogs
                                                                                .infoLogs,
                                                                              function (
                                                                                iLog,
                                                                                index
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          iLog.message
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.logStatus ===
                                                      "CREATED"
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "pa-4 rounded-lg extra-padding",
                                                                    staticStyle: {
                                                                      border:
                                                                        "0px",
                                                                    },
                                                                    attrs: {
                                                                      flat: "",
                                                                      elevation:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Created At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              new Date(
                                                                                item.createdOn
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Last Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " Yet to run "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Next Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " Will run anytime before " +
                                                                            _vm._s(
                                                                              new Date(
                                                                                new Date(
                                                                                  item.createdOn
                                                                                ).getTime() +
                                                                                  3600000 *
                                                                                    4
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Run status : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " The first run will be within 4 hours from the created time. The rule's check frequency( of 6 or 12 or 24 hours) will be considered only from the second run. "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.logStatus ===
                                                      "NOCAMPAIGNSFOUND"
                                                        ? [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                attrs: {
                                                                  colspan:
                                                                    headers.length,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "pa-4 rounded-lg extra-padding",
                                                                    staticStyle: {
                                                                      border:
                                                                        "0px",
                                                                    },
                                                                    attrs: {
                                                                      flat: "",
                                                                      elevation:
                                                                        "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Last Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            new Date(
                                                                              item.runLogs.runStartAt
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Next Run At : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            new Date(
                                                                              item
                                                                                .runLogs
                                                                                .runEndAt +
                                                                                3600000 *
                                                                                  _vm.getFreqValue(
                                                                                    item.ruleFreq
                                                                                  )
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Run status : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getRunStatusString(
                                                                                item
                                                                                  .runLogs
                                                                                  .runningStep
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0  d-flex align-baseline justify-end automation-header",
                                                                        staticStyle: {
                                                                          "justify-content":
                                                                            "flex-start !important",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Error messages : "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-list",
                                                                          {
                                                                            staticStyle: {
                                                                              padding:
                                                                                "1px 0px",
                                                                            },
                                                                          },
                                                                          _vm._l(
                                                                            [0],
                                                                            function (
                                                                              error,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "v-list-item",
                                                                                {
                                                                                  key: index,
                                                                                  staticStyle: {
                                                                                    "align-items":
                                                                                      "start",
                                                                                    "min-height":
                                                                                      "12px",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        color:
                                                                                          "crimson",
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item
                                                                                            .runLogs
                                                                                            .errors[
                                                                                            index
                                                                                          ]
                                                                                            .message
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  "Information Logs : "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item.runLogs
                                                                      .infoLogs
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0  d-flex align-baseline justify-end automation-header",
                                                                            staticStyle: {
                                                                              "justify-content":
                                                                                "flex-start !important",
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._l(
                                                                              item
                                                                                .runLogs
                                                                                .infoLogs,
                                                                              function (
                                                                                iLog,
                                                                                index
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          iLog.message
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      [
                                                        item.lastRun &&
                                                        item.lastRun.success ===
                                                          1
                                                          ? _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "json-viewer",
                                                                  {
                                                                    attrs: {
                                                                      "expand-depth": 2,
                                                                      value:
                                                                        item.lastRun,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        item.lastRun &&
                                                        item.lastRun.success ===
                                                          2
                                                          ? _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "json-viewer",
                                                                  {
                                                                    attrs: {
                                                                      "expand-depth": 2,
                                                                      value:
                                                                        item.lastRun,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        item.lastRun &&
                                                        item.lastRun.success ===
                                                          0
                                                          ? _c(
                                                              "span",
                                                              [
                                                                _c(
                                                                  "json-viewer",
                                                                  {
                                                                    attrs: {
                                                                      "expand-depth": 2,
                                                                      value:
                                                                        item.lastRun,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.logrulesType",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.status
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.logrulesType
                                                                ) +
                                                                " Advanced Rule "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      item.logStatus
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.logrulesType
                                                                ) +
                                                                " Standard Rule "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.selectedARlogs,
                                              callback: function ($$v) {
                                                _vm.selectedARlogs = $$v
                                              },
                                              expression: "selectedARlogs",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 rounded-lg extra-padding",
                                  attrs: { flat: "", elevation: "3" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            lg: "8",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("Chart", {
                                            attrs: {
                                              chartdata: _vm.metricsChartData,
                                              options: _vm.chartOptions,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-8",
                                          attrs: {
                                            cols: "12",
                                            lg: "4",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "rounded-lg mt-8",
                                                attrs: {
                                                  label: "Select Account",
                                                  items: _vm.allAccounts,
                                                  outlined: "",
                                                  dense: "",
                                                  "item-text": "name",
                                                  "item-value": "teamId",
                                                  "return-object": "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.selectedAccountChanged,
                                                },
                                                model: {
                                                  value: _vm.selectedAccount,
                                                  callback: function ($$v) {
                                                    _vm.selectedAccount = $$v
                                                  },
                                                  expression: "selectedAccount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "rounded-lg",
                                                attrs: {
                                                  items: _vm.allRulesForAccount,
                                                  label: "Select Rule",
                                                  outlined: "",
                                                  dense: "",
                                                  "item-text": "ruleName",
                                                  "item-value": "ruleId",
                                                  "return-object": "",
                                                  disabled: !_vm.selectedAccount
                                                    .teamId,
                                                },
                                                on: {
                                                  change:
                                                    _vm.selectedRuleChanged,
                                                },
                                                model: {
                                                  value: _vm.selectedRule,
                                                  callback: function ($$v) {
                                                    _vm.selectedRule = $$v
                                                  },
                                                  expression: "selectedRule",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-label", [
                                            _vm._v(_vm._s(_vm.updatedDaysText)),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                " d-flex align-center justify-end flex-wrap w-100 overview-date-picker",
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "menu",
                                                  staticClass:
                                                    " overview-menu date-picker",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.rangePicker,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "min-width": "auto",
                                                    left: "",
                                                  },
                                                  on: {
                                                    "update:returnValue": function (
                                                      $event
                                                    ) {
                                                      _vm.rangePicker = $event
                                                    },
                                                    "update:return-value": function (
                                                      $event
                                                    ) {
                                                      _vm.rangePicker = $event
                                                    },
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "v-date-picker-cs w-sm-100 ",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "revert",
                                                                  },
                                                                  attrs: {
                                                                    "prepend-icon":
                                                                      "mdi-calendar",
                                                                    readonly:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.filterDateRangeText,
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.filterDateRangeText = $$v
                                                                    },
                                                                    expression:
                                                                      "filterDateRangeText",
                                                                  },
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                  model: {
                                                    value: _vm.rangePicker,
                                                    callback: function ($$v) {
                                                      _vm.rangePicker = $$v
                                                    },
                                                    expression: "rangePicker",
                                                  },
                                                },
                                                [
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              flex: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "flex-container column mt-3",
                                                                staticStyle: {
                                                                  "list-style-type":
                                                                    "none",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "TODAY & YESTERDAY"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.todayAndYeserday"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "WEEKTODATE"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.weekToDate"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "LASTWEEK"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.lastWeek"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "LASTSEVENDAYS"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.lastSevenDays"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "MONTHTODATE"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.monthToDate"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "LASTMONTH"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.lastMonth"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "flex-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ma-1",
                                                                        attrs: {
                                                                          label:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPreset(
                                                                              "LASTTHIRTYDAY"
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "dateRangePresets.lastThirtyDays"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              flex: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  "no-title":
                                                                    "",
                                                                  scrollable:
                                                                    "",
                                                                  range: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.filterDateRangeModel,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.filterDateRangeModel = $$v
                                                                  },
                                                                  expression:
                                                                    "filterDateRangeModel",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "grey",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        _vm.rangePicker = false
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Cancel"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.filterDateRangeModel &&
                                                                        _vm
                                                                          .filterDateRangeModel
                                                                          .length !==
                                                                          2,
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.changePeriod,
                                                                    },
                                                                  },
                                                                  [_vm._v("OK")]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("DecisionAlert", {
                    attrs: {
                      DecisionAlertIcon: _vm.DecisionAlertIcon,
                      DecisionAlert: _vm.decisionAlertFlag,
                      DecisionAlertTitle: _vm.decisionAlertTitle,
                      DecisionAlertMessage: _vm.decisionAlertMessage,
                      DecisionAlertData: _vm.decisionAlertData,
                    },
                    on: {
                      emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                      emitDecisionAlertOkClicked: function ($event) {
                        return _vm.decisionOkAction($event)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "450", persistent: "" },
                          model: {
                            value: _vm.infoAlertFlag,
                            callback: function ($$v) {
                              _vm.infoAlertFlag = $$v
                            },
                            expression: "infoAlertFlag",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "headline grey lighten-2" },
                                [_vm._v(" " + _vm._s(_vm.infoAlertTitle) + " ")]
                              ),
                              _c("v-card-text", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.appleMsgs, function (msg, i) {
                                      return _c("li", { key: i }, [
                                        _vm._v(" " + _vm._s(msg) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.infoAlertFlag = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("infoAlert.ok")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }